// ================================================ PROJECT SETTING ===========================================
exports["ESTIMATED_APPLICANTS"] = "0";
exports["RESEARCH_QUALITY"] = "95";
exports["REPLY_RATE"] = "18";
exports["SHARE_OF_POSITIVE_REPLIES"] = "23";
exports["TRANSFER_TO_PHONE_INTERVIEW"] = "70";
exports["SUCCESS_RATE_PHONE_INTERVIEW"] = "50";
exports["SUCCESS_RATE_PRESENTATION_TO_CLIENT"] = "66";
exports["SUCCESS_RATE_INTERVIEW_AT_CLIENT"] = "40";
exports["SUCCESS_RATE_CONTRACT_NEGOTIATION"] = "80";

// ================================================== TIME FRAME SETTING =======================================
exports["TIME_EXPENDITURE_MANAGING_APPLICATIONS"] = "6";
exports["TIME_EXPENDITURE_IDENTIFYING_CANDIDATES"] = "9";
exports["TIME_EXPENDITURE_CONTACTING_CANDIDATES"] = "3";
exports["TIME_EXPENDITURE_HANDLING_REPLIES"] = "6";
exports["TIME_EXPENDITURE_CONDUCTING_PHONE_INTERVIEW"] = "60";
exports["TIME_EXPENDITURE_MANAGING_PHONE_INTERVIEW_WITH_CLIENT"] = "30";
exports["TIME_EXPENDITURE_HAVING_PERSONAL_MEETING"] = "60";
exports["PERCENTAGE_PROJECT_MANAGEMENT"] = "25";

// ======================================================= COOKIE EXPIRE TIME ======================================
exports["COOKIE_EXPIRE_TIME"] = 60 * 60 * 10;

// ======================================================= UI ======================================
exports["PRIMARY_COLOR_TEXT"] = "color: #4F46E5";
exports["PRIMARY_COLOR_BG"] = "background-color: #4F46E5";
// ======================================================= ROLES ======================================
exports["ROLE_CLIENT"] = "ROLE_CLIENT";
exports["ROLE_CLIENT_NEW"] = "ROLE_CLIENT_NEW";
exports["ROLE_ADMIN"] = "ROLE_ADMIN";
