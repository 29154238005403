<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content w-[50%] 2xl:w-[40%]">
      <div class="m-3">
        <div class="border-b-2 border-gray-400 pb-2">
          <p class="font-bold text-xl">
            {{
              isForJob ? jobOrCompanyDetails.job_name : jobOrCompanyDetails.name
            }}
          </p>
        </div>

        <div class="mt-3">
          <div
            class="w-fit badge rounded-md mr-3 mt-2"
            :style="`border:${allHashtagLevels[category]}; background-color: ${allHashtagLevels[category]}`"
            v-for="(category, index) in jobOrCompanyDetails.tag_category"
            :key="index"
          >
            <p class="mr-2">{{ jobOrCompanyDetails.job_tags[index] }}</p>
            <span
              class="cursor-pointer"
              @click="onTagRemove(jobOrCompanyDetails.job_tags[index])"
            >
              <XIcon class="w-4 h-4 hover:text-gray-400 text-white" />
            </span>
          </div>
        </div>

        <div class="mt-10 h-72">
          <input-with-dropdown
            :selectedData="jobOrCompanyDetails.job_tags"
            @onAddTag="onAddTag"
            placeholder="Add New Tag"
            :topLevel=true
          />
        </div>

        <div class="mt-10 h-72">
          <input-with-dropdown
            :selectedData="jobOrCompanyDetails.job_tags"
            @onAddTag="onAddTag"
            placeholder="Add New Tag"
            :isForJob="isForJob"
          />
        </div>

        <div class="flex flex-1 flex-row w-full justify-end items-end mt-4">
          <button
            class="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-md"
            @click="onSaveOrCancel()"
          >
            <p class="text-black">Cancel</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/solid";
import InputWithDropdown from "../../ui/InputWithDropdown.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { storeGeneralDataInCache } from "../../helperFunctions/DataCaching";

export default {
  props: ["details", "isForJob", "topLevel"],
  components: {
    XIcon,
    InputWithDropdown,
  },
  data() {
    return {
      jobOrCompanyDetails: {},
      isAddNew: false,
    };
  },
  computed: {
    ...mapGetters([
      "allJobList",
      "authorizationHeader",
      "backendUrl",
      "allTags", // [{color: <>, name: <>}]
      "allHashtagLevels",
    ]),

    settingTagUrl() {
      return this.isForJob
        ? `${this.backendUrl}/tag/set-for-job?jobId=${this.jobOrCompanyDetails.job_id}`
        : `${this.backendUrl}/tag/set-for-company?companyId=${this.jobOrCompanyDetails.company_id}`;
    },
  },
  mounted() {
    // use this technique to make a deep copy and nested arrays aswell
    this.jobOrCompanyDetails = JSON.parse(JSON.stringify(this.details));

    this.fetchAllTags();
  },
  methods: {
    ...mapActions(["jobHashtagsChange", "addHashtag", "setAllTags"]),

    // Function to handle removing a hashtag from the job details
    onTagRemove(hashtagName) {
      // Find the index of the specified 'hashtagName' in the 'job_tags' array of 'jobOrCompanyDetails'
      const index = this.jobOrCompanyDetails.job_tags.findIndex(
        (value) => value === hashtagName
      );

      // Remove the 'hashtagName' and its corresponding tag color from the 'job_tags' and 'tag_category' arrays
      this.jobOrCompanyDetails.job_tags.splice(index, 1);
      this.jobOrCompanyDetails.tag_category.splice(index, 1);

      const body = [...this.jobOrCompanyDetails.job_tags];
      const headers = this.authorizationHeader;

      // Send a PUT request to update the job tags for the current job
      axios.put(this.settingTagUrl, body, { headers });
    },
    onAddTag(hashtag, hashtagLevel, fromDropdown, domain) {
      const headers = this.authorizationHeader;
      // IF cond: Check if the hashtag is selected from a dropdown
      if (fromDropdown) {
        let body;
        if(domain){
        body = [...this.jobOrCompanyDetails.job_tags, hashtag.name, domain];
        }
        else{
        body = [...this.jobOrCompanyDetails.job_tags, hashtag.name];
        }
        axios.put(this.settingTagUrl, body, { headers });

        // Update the jobOrCompanyDetails object locally by adding the new hashtag name to job_tags and pushing the hashtag object to the hashtags array
        this.jobOrCompanyDetails.job_tags.push(hashtag.name);
        this.jobOrCompanyDetails.tag_category.push(hashtag.category);
      }
      // ELSE cond: if hashtag is newly created
      else {
        let body;
        if(domain){
        body = {
          [hashtag]: hashtagLevel,
          cluster: domain,
        };}
        else{
        body = {
          [hashtag]: hashtagLevel,
        };
        }
        axios
          .post(`${this.backendUrl}/tag/create-tags`, body, { headers })
          .then(() => {
            // Update the jobOrCompanyDetails object locally by adding the new hashtag to job_tags and its level name to tag_category,
            this.jobOrCompanyDetails.job_tags.push(hashtag);
            this.jobOrCompanyDetails.tag_category.push(hashtagLevel);

            const updatedBody = [...this.jobOrCompanyDetails.job_tags, domain];
            axios.put(this.settingTagUrl, updatedBody, { headers });
            this.addHashtag({
              hashtag: { name: hashtag, category: hashtagLevel },
            });
          })
          .catch((error) => console.log(error));
      }
    },
    onSaveOrCancel() {
      if (this.isForJob)
        this.jobHashtagsChange({
          jobId: this.jobOrCompanyDetails.job_id,
          hashtags: this.jobOrCompanyDetails.job_tags,
          hashtagCategory: this.jobOrCompanyDetails.tag_category,
        });

      this.$emit("onHashtagSave");
    },

    fetchAllTags() {
      let headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + `/tag/all-tags`, {
          headers,
        })
        .then((response) => {
          this.setAllTags(response.data);
          storeGeneralDataInCache("allTags", response.data);
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: white;
  margin: 4% auto; /*  from the top and centered */
  padding: 5px;
  border: 1px solid #888;
  border-radius: 10px;
}
</style>
