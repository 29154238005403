export default {
  allJobList(state) {
    return state.allJobList;
  },
  allTags(state) {
    return state.allTags;
  },
  levelsHexColors(state) {
    return state.levelsHexColors;
  },
  companyLevelsHexColors(state) {
    return state.companyLevelsHexColors;
  },
  topLevelsHexColors(state) {
    return state.topLevelsHexColors;
  },
  allHashtagLevels(state) {
    return { ...state.levelsHexColors, ...state.companyLevelsHexColors };
  },
};
