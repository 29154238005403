<template>
  <skeleton-kpi-score-widget v-if="loading" />

  <div v-else>
    <div
      class="w-full h-72 bg-white rounded-3xl shadow-2xl flex flex-col justify-around p-2 mr-2 mt-5"
      style="background-color: rgba(104, 67, 149, 0.3)"
    >
      <div>
        <div class="flex flex-col" @mouseover="hover = false">
          <button
            @click="goToDetails"
            class="flex flex-row text-xs items-center justify-end mr-1 font-bold text-gray-600 hover:text-black"
            v-show="!(isRoleClient || isRoleCustomClient)"
          >
            {{ $t("dashboard.project-analysis") }}
            <ChevronRightIcon class="w-3 h-3" />
          </button>
          <div @mouseover="hover = false">
            <p class="ml-2 text-2xl text-black">{{ name }}</p>
            <p class="ml-2 text-xs text-gray-600">
              {{ $t(`dashboardKPIScore.${rowHeaders}`) }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex justify-center mt-5">
        <vue-gauge :refid="'weekly-gauge'" :options="kpiScoreGaugeOptions" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import { mapActions, mapGetters } from "vuex";
import SkeletonKpiScoreWidget from "./SkeletonKpiScoreWidget.vue";
import VueGauge from "vue-gauge";
import { ChevronRightIcon } from "@heroicons/vue/solid";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../../../components/helperFunctions/DataCaching";

export default {
  components: {
    SkeletonKpiScoreWidget,
    VueGauge,
    ChevronRightIcon,
  },
  props: ["loadingToggle", "jobId"],
  data() {
    return {
      name: "",
      kpi: "",
      score: "",
      rowHeaders: [],
      hover: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters([
      "currentJobId",
      "authorizationHeader",
      "backendUrl",
      "isRoleClient",
      "isRoleCustomClient",
    ]),
    toggleLoadingSaveButton() {
      return this.$store.getters.toggleLoading;
    },

    kpiScoreGaugeOptions() {
      let kpiPercentage = JSON.parse(this.kpi.split("%")[0]);
      let needleValue = kpiPercentage;

      if (kpiPercentage > 20 && kpiPercentage <= 50) needleValue = 20;
      else if (kpiPercentage > 50 && kpiPercentage <= 90) needleValue = 37;
      else if (kpiPercentage > 90 && kpiPercentage <= 120) needleValue = 57;
      else if (kpiPercentage > 120 && kpiPercentage <= 150) needleValue = 77;
      else if (kpiPercentage > 150) needleValue = 97;
      return {
        arcPadding: 6,
        needleValue: needleValue,
        arcDelimiters: [20, 40, 60, 80, 99],
        arcColors: [
          "#f87171",
          "#45d67a",
          "#37c069",
          "#24a252",
          "#12853c",
          "#12853c",
        ],
        arcLabels: [],
        needleColor: "rgb(75 85 99)",
        centralLabel: this.kpi,
        rangeLabel: ["", ""],
        arcOverEffect: true,
        chartWidth: 240,
      };
    },
  },

  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
    toggleLoadingSaveButton() {
      this.fetchDataFromBackend(this.currentJobId);
    },
  },

  methods: {
    ...mapActions("sidebar", ["setSelectedViewDashboard"]),
    fetchDataFromBackend(jobId) {
      if (jobId !== null) {
        this.loading = true;
        const headers = this.authorizationHeader;
        axios
          .get(
            this.backendUrl + "/widget/project-kpi-score" + "?jobId=" + jobId,
            {
              headers,
            }
          )
          .then((response) => {
            storeJobDataInCache(jobId, response.data, "KpiScore");
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.name = data.name;
      this.rowHeaders = data.row_headers[0];
      this.kpi = data.table_data[0][0];
      this.kpiScoreCleaner(this.kpi);
      this.loading = false;
    },
    kpiScoreCleaner(kpi) {
      this.score = kpi.replace("%", "");
    },
    goToDetails() {
      this.setSelectedViewDashboard("DetailsCard");
    },
  },
  mounted() {
    if (this.currentJobId !== null || this.jobId) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "KpiScore")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "KpiScore")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
<style scoped>
.kpiLevel {
  animation: draw 2s ease-in-out;
}

@keyframes draw {
  0% {
    width: 0;
  }
}

.absolutePosition {
  right: 260px;
  top: 338px;
  z-index: 10;
}
.triangle {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 26.6px;
  border-color: transparent transparent transparent rgb(209 213 219);
}
.shadowBox {
  -webkit-box-shadow: -9px -1px 7px 1px rgba(0, 0, 0, 0.28);
  box-shadow: -9px -1px 7px 1px rgba(0, 0, 0, 0.28);
}
.borderWidth {
  border-width: 15px;
}
</style>
