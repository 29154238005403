export default {
  "header": {
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])}
  },
  "login-page": {
    "header-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "invalid-credentials-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or Password invalid"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Your Recruiting Dashboard"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "forget-password-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address and you will receive password reset link on your email"])},
    "password-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Reset"])}
  },
  "admin-page": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "add-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new user"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "assigned-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Companies and Jobs"])},
    "admin-user-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Users can access all companies and jobs"])},
    "select-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])}
  },
  "greetings-page": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "welcome-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Your Recruiting Dashboard"])},
    "subtitle-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the ATS to use project data in Dashr."])},
    "description-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can always customize the connection in the settings."])},
    "connect-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "choose-later-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose later"])}
  },
  "signup-page": {
    "header-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Your Password"])},
    "account-check-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "login-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "invalid-general-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill the mandatory fields"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "firstname-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name must have 2 characters or more"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "lastname-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name must have 2 characters or more"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "email-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter a valid address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must have 3 characters or more"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Password"])},
    "repeat-password-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])}
  },
  "notfound-page": {
    "header-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "loggedin-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page could not be found - maybe check out our"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "no-loggedin-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page could not be found -"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "check-dashboard-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to check out the Dashboard"])}
  },
  "sidebar": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "job-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job details"])}
  },
  "projekt-einstellungen": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project settings"])},
    "general-project-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Project Information"])},
    "general-project-information-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["set the basic project details here"])},
    "stage-mapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage Mapping"])},
    "stage-mapping-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["map any stage from any ATS pipeline / funnel to our internal stages"])},
    "estimated-stage-conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Stage Conversion (%)"])},
    "estimated-stage-conversion-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimate the percentages for the conversion rate of each stage of to the next"])},
    "estimated-time-expenditure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated time expenditure"])},
    "estimated-time-expenditure-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimate the time needed per stage per candidate in minutes here"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "Projekt Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Start"])},
    "Projekt Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Status"])},
    "Projektnummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Code"])},
    "geplante Projektdauer in Wochen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected Duration in Weeks"])},
    "Zu besetzende Vakanzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Vacancies"])},
    "Tatsächliches Abschlussdatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End- Date"])},
    "Projekt pausiert (Wochen)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Paused (Weeks)"])},
    "Allgemeiner Ansprechpartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Contact"])},
    "Fachlicher Ansprechpartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting Specialist"])},
    "Account Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Manager"])},
    "Project Lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Lead"])},
    "Recruiting Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting Specialist"])},
    "First Sourcing Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Sourcing Specialist"])},
    "Second Sourcing Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Sourcing Specialist"])},
    "Recruitee Share Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruitee Share Link"])},
    "Stage Mapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage Mapping"])},
    "sourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sourced"])},
    "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["applied"])},
    "contact request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact request"])},
    "1st contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st contact"])},
    "follow up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["follow up"])},
    "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interested"])},
    "motivation call, kooku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motivation call, kooku"])},
    "phone screen, kooku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone screen, kooku"])},
    "on hold after ti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on hold after ti"])},
    "submitted to customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submitted to customer"])},
    "1st interview, client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st interview, client"])},
    "2nd interview, client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd interview, client"])},
    "evaluation, client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluation, client"])},
    "reference check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reference check"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offer"])},
    "hired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hired"])},
    "inmail 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inmail 1"])},
    "inmail 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inmail 2"])},
    "1 nachfass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Follow up"])},
    "2 nachfass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Follow up"])},
    "3 nachfass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Follow up"])},
    "motivation call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motivation call"])},
    "on hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on hold"])},
    "1st interview client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st interview client"])},
    "2nd interview client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd interview client"])},
    "evaluation client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluation client"])}
  },
  "report-pdf": {
    "project-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project-Reporting-"])},
    "cw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" _CW-"])},
    "message-to-change-from-graph-to-table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the widget to switch graph / table view"])},
    "modal-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date for report"])},
    "modal-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date"])},
    "open pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open PDF"])},
    "Projekt Eigenschaften": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Properties"])},
    "Zeitrahmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeframe"])},
    "Zeiterfassung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Tracking"])},
    "Kandidaten im Prozess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates in Process"])},
    "Partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
    "Stellenbezeichnung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
    "Projektnummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Code"])},
    "zu besetzende Vakanzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Vacancies"])},
    "tatsächliches Abschlussdatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End-Date"])},
    "Allgemeiner Ansprechpartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Contact"])},
    "Fachlicher Ansprechpartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting Specialist"])},
    "Account Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Manager"])},
    "Project Lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Lead"])},
    "Recruiting Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting Specialist"])},
    "First Sourcing Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Sourcing Specialist"])},
    "Second Sourcing Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Sourcing Specialist"])},
    "Aktueller Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Status"])},
    "Projektbeginn / Rebriefing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Start / Rebriefing"])},
    "planmässiges Projektende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled Project End"])},
    "angenommene Projektlaufzeit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Duration in Weeks"])},
    "tatsächliche Projektdauer in Wochen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual Project Duration in Weeks"])},
    "Projekt pausiert(Wochen)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Paused (Weeks)"])},
    "geschätzter Zeitaufwand in h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Time spent (Hours)"])},
    "tatsächlicher Zeitaufwand in h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual Time spent (Hours)"])},
    "Zeitaufwand - anteilig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time spent - Proportional"])},
    "Namen der Kandidaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Names"])},
    "Kandidaten gesamt recherchiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Candidates Sourced"])},
    "Kandidaten kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates Contacted"])},
    "Kandidaten mit Rückmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates with Feedback"])},
    "Interessiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested"])},
    "Telefoninterviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Interviews"])},
    "Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced to Client"])},
    "Persönliches Gespräch Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview with Client"])},
    "Einstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hired"])},
    "Projektstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Status"])},
    "Gesamt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "Ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual"])},
    "Ziel aus KPIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPI Targets"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Vertragsangebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
    "Projektanalyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Analysis"])},
    "Recherchequalität": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research Quality"])},
    "Rückmeldequote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback Rate"])},
    "davon positive Rückmeldungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive Feedback"])},
    "Überführung zum Telefoninterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to Telephone Interview"])},
    "Erfolgsquote - TI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - TI"])},
    "Erfolgsquote - Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Introduction to Client"])},
    "Erfolgsquote - Interviews beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Interview with Client"])},
    "Erfolgsquote - Vertragsverhandlung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Contract Negotiation"])},
    "Gesamtperformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall Performance"])},
    "KPIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPIs"])},
    "Soll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
    "KPI-Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPI Score"])},
    "Quantität": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])}
  },
  "right-menu-mobile": {
    "company-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*click on the company name to edit/delete"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Settings"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Settings"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "ats-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATS Settings"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
  },
  "clients-page": {
    "current-clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Current Clients"])},
    "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
    "all-clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Clients"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "archived-clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive Clients"])},
    "pending-clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Clients"])},
    "former-clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former Clients"])}
  },
  "client-details-page": {
    "all-vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Vacancies"])},
    "no-vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Vacancies"])},
    "in-planning-vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In preparation"])},
    "no-in-planning-vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No projects in pre-phase"])},
    "vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancies"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "no-archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No archived Job"])},
    "contact-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Details"])}
  },
  "dashboard": {
    "kpi-description-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kpi Score?"])},
    "kpi-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The KPI score is calculated from the <b>actual values</b> from the ATS and the <b>target values</b> from the KPIs of past projects. You can find a more detailed breakdown in the"])},
    "project-analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project analysis"])},
    "days-to-kpi-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days to KPI target"])},
    "kpi-target-full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPI Target reached"])},
    "conversionrate-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion rate"])},
    "conversionrate-successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion rate successful"])},
    "conversionrate-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion rate ok"])},
    "conversionrate-unrealized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion rate unrealized"])},
    "conversion-first-tipp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only a few applicants meet the requirements for an interview"])},
    "conversion-second-tipp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are the job descriptions unambiguous and clearly understandable?"])},
    "conversion-third-tipp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are the job advertisements published via channels that are suitable for the target group?"])},
    "calendar-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CW"])},
    "no-candidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No candidates in the next stages"])},
    "save-button-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project settings updated"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  },
  "dashboardProjectDetails": {
    "Projektdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Details"])},
    "Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "Ansprechpartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "zu besetzende Vakanzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancies"])},
    "Projektnummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Code"])},
    "Projektstart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Start"])},
    "Planmäßiges Projektende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project End (Scheduled)"])}
  },
  "dashboardWeaklyGauge": {
    "Zahlen der Kandidaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. of Candidates"])},
    "kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacted"])},
    "geschätzte Zahlen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Count"])},
    "einstellung Zahlen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hired Count"])},
    "Telefoninterviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interviewed"])},
    "Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presented to Client"])}
  },
  "dashboardKPIScore": {
    "Gesamt Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall Performance"])}
  },
  "dashboardProjectStatus": {
    "Projekt Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Status"])},
    "Forecast Time-to-Sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecast Time-to-Sign"])},
    "Kandidaten gesamt recherchiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates sourced"])},
    "Kandidaten kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates contacted"])},
    "Kandidaten mit Rückmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates replied"])},
    "Interessiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested"])},
    "Telefoninterviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone Interviews"])},
    "Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced to Client"])},
    "Persönliches Gespräch Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview with Client"])},
    "Vertragsangebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
    "Einstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hired"])},
    "Bewerbungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
    "Recherchequalität": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research Quality"])},
    "Rückmeldequote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback Rate"])},
    "davon positive Rückmeldungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive Feedback"])},
    "Überführung zum Telefoninterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transition Telephone Interview"])},
    "Erfolgsquote - TI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - TI"])},
    "Erfolgsquote - Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Introduction to Client"])},
    "Erfolgsquote - Interview beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Interview with Client"])},
    "Erfolgsquote - Vertragsverhandlung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Negotiations"])},
    "Gesamt geschätzter Zeitaufwand in h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated time expenditure in h (total)"])},
    "Projekt Ende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project End"])}
  },
  "detailsCard": {
    "page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Details"])},
    "placeholder-searchbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword-Search"])},
    "connect-ats-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to ATS Job"])},
    "transfer-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Job"])},
    "to-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Project"])},
    "fetch-job-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Data"])},
    "fetch-tags-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetch Tags from ATS"])},
    "show-job-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Data"])}
  },
  "setpassword-page": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please set your password here"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password needs to have at least 3 characters"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Password"])},
    "repeat-password-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])}
  },
  "projectStatusChanges": {
    "project-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Status Settings"])},
    "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEFORE"])},
    "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFTER"])},
    "time-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Frame Settings"])}
  },
  "projectTimeExpenditurePerWeek": {
    "Zeitaufwand in h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Expenditure (h)"])},
    "Pro Woche Schätzwert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(estimated/week)"])}
  },
  "timeTracking": {
    "Zeiterfassung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Tracking"])},
    "Bewerbermanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Management"])},
    "Kandidaten-Ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Sourcing"])},
    "Kandidatenansprache": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Contacting"])},
    "Rückmeldung - Gesamt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback - Total"])},
    "Telefoninterview / Interesse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone interview / interest"])},
    "Vorstellung Kunde & follow-ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction to Client & Follow-ups"])},
    "persönliche Gespräch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Communication"])},
    "Projektrecherche und Orga (in Prozent)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Research and Org. (%)"])}
  },
  "donutChart": {
    "Kandidaten gesamt recherchiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates sourced - total"])},
    "Kandidaten kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates Contacted"])},
    "Kandidaten mit Rückmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates replied"])},
    "Interessiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested"])},
    "Telefoninterviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone Interviews"])},
    "Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction to Client"])},
    "Persönliches Gespräch Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview with Client"])},
    "Vertragsangebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
    "Einstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hired"])},
    "Leads generiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads Generated"])},
    "Tage bis zum KPI Ziel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days To KPI Target"])},
    "Leads ausstehend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads Pending"])}
  },
  "conversionRate": {
    "Projektanalyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Analysis"])},
    "Tatsächliche Conversionrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual Conversion Rate"])},
    "Recherchiert - Kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sourced - Contacted"])},
    "Kontaktiert - Rüchmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacted - Feedback"])},
    "Rüchmeldung - Interessiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback - Interested"])},
    "Interessiert - Telefoninterviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested - Telephone Interviews"])},
    "Telefoninterviews - Vostellung Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone Interview - Introduction to Client"])},
    "Vostellung Kunden - pG Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction to Client - Interview with Client"])},
    "pG Kunden - Vertragsangebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview with Client - Offer"])},
    "Vertragsangebot - Einstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer - Hired"])},
    "Erwartete Conversionrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected Conversion Rate"])}
  },
  "targetComparison": {
    "Ziel / Ist-Vergleich - Gesamtprojekt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal / Reached : Comparison - Overall Project"])},
    "Ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reached"])},
    "Ziel aus KPIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPI -Goal"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "candidateInProcess": {
    "Kandidaten im Prozess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates in Process"])},
    "potentielle Telefoninterviews (interested)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potential Phone Interviews (interested)"])},
    "potentielle Profil-Präsentationen (on hold)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potential Profile Presentations (on hold)"])},
    "Kandidat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANDIDATE"])},
    "Nächster Schritt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEXT STEP"])}
  },
  "detailsHistoricView": {
    "Projektzahlen aus ATS (historic view)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Numbers from ATS (historic view)"])},
    "Sourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sourced"])},
    "Sourced - Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sourced - Out"])},
    "Kandidaten kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates contacted"])},
    "Kandidaten nachgefasst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates followed up"])},
    "Bewerbungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
    "Out nach Bewerbungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out after applications"])},
    "Interessierte Kandidaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested candidates"])},
    "Telefoninterview - anstehend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Interview - upcoming"])},
    "on hold nach TI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On hold after phone interview"])},
    "Out nach Telefoninterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out after phone interview"])},
    "Vorstellung Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted To Client"])},
    "Out nach Vorstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected After Review"])},
    "Persönliches Gespräch Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Interview"])},
    "Out nach pG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected After Interview"])},
    "Vertragsangebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Offer"])},
    "Einstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hired"])},
    "Out (kontaktiert+nachgefasst+Interesse)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out (contacted+followed up+interest)"])},
    "Wochenstunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Hours"])}
  },
  "detailsProjectAnalysis": {
    "Projektanalyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Analysis"])},
    "Recherchequalität": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research Quality"])},
    "Rückmeldequote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback Rate"])},
    "davon positive Rückmeldungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive Feedback"])},
    "Überführung zum Telefoninterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to Telephone Interview"])},
    "Erfolgsquote - TI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - TI"])},
    "Erfolgsquote - Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Introduction to Client"])},
    "Erfolgsquote - Interviews beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Interview with Client"])},
    "Erfolgsquote - Vertragsverhandlung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Contract Negotiation"])},
    "Gesamtperformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall Performance"])},
    "KPIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPIs"])},
    "Soll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
    "Ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reached"])},
    "KPI-Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPI Score"])},
    "Quantität": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])}
  },
  "add-new-job": {
    "add-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Company"])},
    "change-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Company"])},
    "add-new-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Company"])},
    "add-new-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Contact"])},
    "edit-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Contact"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "company-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone #"])},
    "company-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
    "company-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "company-point-of-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point of Contact"])},
    "company-point-of-contact-position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point of Contact Position"])},
    "mandatory-field-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete all fields marked with *"])},
    "company-status-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "company-status-archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
    "company-status-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "company-status-closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "company-streetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Apt, etc "])},
    "company-zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
    "company-poBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PO Box"])},
    "company-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "company-street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "company-first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "company-last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "company-pronoun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronoun"])},
    "company-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "company-position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "add-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Job"])},
    "add-new-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Job"])},
    "edit-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Job"])},
    "edit-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Company"])},
    "job-project-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Start Date"])},
    "job-num-opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Openings"])},
    "job-est-duration-in-weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est. Duration in Weeks"])},
    "job-general-contact-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Contact"])},
    "job-professional-contact-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting Specialist"])},
    "job-research-quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research quality"])},
    "job-feedback-rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply rate"])},
    "job-which-positive-rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate of positive replies"])},
    "job-telephone-interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to TI"])},
    "job-success-rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - TI"])},
    "job-success-rate-introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Introduction to Client"])},
    "job-success-rate-interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Interview with Client"])},
    "job-success-rate-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate - Contract Negotiations"])},
    "job-application-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Management"])},
    "job-candidate-ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Sourcing"])},
    "job-candidate-approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Contact"])},
    "job-feedback-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback - Total"])},
    "job-interview-interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone Interview / Interest"])},
    "job-appointment-customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone Appointment - Customer"])},
    "job-personal-conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Conversation"])},
    "job-project-research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research And Org (In %)"])},
    "contact-imported-in-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This contact is still asssigned to a project - please delete it there first"])}
  },
  "disqualificationReason": {
    "disqualificationByReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disqualified Candidates - Reason"])},
    "disqualificationByStage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disqualified Candidates - Stage"])}
  },
  "confirm-msg": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following will be deleted:"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...wait to be re-directed..."])}
  },
  "common-elements": {
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "toClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to Client"])},
    "withATSJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to ATS job"])},
    "Give UI Feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give UI Feedback"])},
    "RequestNewProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request New Project"])}
  },
  "point-of-contacts": {
    "no-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contacts available for import"])},
    "import-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Contacts"])}
  },
  "job-clustering": {
    "all-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Jobs"])},
    "your-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Jobs"])},
    "visit-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Job"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use As Template"])},
    "edit-hashtags-for-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Hashtags For Job"])},
    "input-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["press enter to add a new tag or select from the list"])},
    "actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual"])},
    "expected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exptected"])},
    "add-new-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Tag"])},
    "tag-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag Name"])},
    "new-tag-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New tag Name"])},
    "new-tag-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Tag Level"])}
  },
  "job-kpi": {
    "candidate-sourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Candidates Sourced"])},
    "candidate-contacted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates Contacted"])},
    "candidate-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates with Feedback"])},
    "candidate-interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested"])},
    "candidate-phoned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Interviews"])},
    "candidate-introduced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced to Client"])},
    "candidate-interviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview with Client"])},
    "candidate-offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
    "candidate-hired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hired"])}
  }
}