<template>
  <button
    class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center w-52 justify-center"
    @click="onSubmit"
  >
    Submit
  </button>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ROLE_CLIENT, ROLE_CLIENT_NEW, ROLE_ADMIN } from "../../../../constant/Constants";
import axios from "axios";
import FormValidation from "../../../../components/helperFunctions/FormValidation.js";

export default {
  computed: {
    role() {
      return this.controls.role.value;
    },

    ...mapGetters([
      "authorizationHeader",
      "backendUrl",
      "controls",
      "isFormValidated",
      "isModal",
      "assignedCompaniesList",
      "roles",
    ]),
  },
  methods: {
    ...mapActions([
      "setControls",
      "setIsFormValidated",
      "setIsModal",
      "getAllUserList",
    ]),

    onSubmit() {
      let formValidationResponse = FormValidation(this.controls);
      this.setIsFormValidated(formValidationResponse.isFormValidated);
      this.setControls({ ...formValidationResponse.controls });
      // this.controls = formValidationResponse.controls;

      if (this.isFormValidated) {
        // to get all the assigned company and job ids only
        let jobIds = [];
        let companyIds = this.assignedCompaniesList.map((company) => {
          company.jobs.map((job) => {
            jobIds.push(job.id);
          });
          return company["company_id"];
        });
        let role = this.roles.filter((value) => {
          return value.name === this.controls.role.value;
        });

        let body = {
          name: `${this.controls["first-name"].value} ${this.controls["last-name"].value}`,
          mail: this.controls["email-address"].value,
          roles: [{ id: role[0].id, name: role[0].name }],
          companyIds,
          jobIds,
          admin: role[0].name == ROLE_ADMIN,
        };

        const headers = this.authorizationHeader;

        // if user already created than edit that user else create a new one
        if (this.$route.params.userId !== "0") {
          axios
            .put(this.backendUrl + "/auth/user/save", body, { headers })
            .then(() => {
              this.postUserSaveAction(
                companyIds,
                this.$route.params.userId,
                role
              );
            })
            .catch((error) => console.log(error));
        } else {
          axios
            .post(this.backendUrl + "/auth/user/register", body, { headers })
            .then((response) => {
              this.postUserSaveAction(
                companyIds,
                response.data.created_user_id,
                role
              );
            })
            .catch((error) => console.log(error));
        }
      }
    },
    postUserSaveAction(companyIds, userId, role) {
      // if role is not admin or user then set favorite company to the database for client user
      if (role[0].name !== ROLE_CLIENT && role[0].name !== ROLE_CLIENT_NEW) {
        this.setIsModal("succesModal");
      } else {
        const headers = this.authorizationHeader;

        let body = {
          favorite_company_id: companyIds[0],
          user_id: `${userId}`,
        };
        axios
          .post(this.backendUrl + "/company/set-single", body, { headers })
          .then(() => {
            this.getAllUserList();
            this.setIsModal("succesModal");
          });
      }
    },
  },
};
</script>
