<template>
  <div class="flex flex-col rounded-lg shadow">
    <div
      class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg h-full w-full"
    >
      <div
        :key="index"
        v-for="index in [1, 2, 3, 4, 5, 6, 7]"
        class="bg-white w-full p-4 h-full"
      >
        <div class="h-16 w-full px-5 pt-6 mb-2 rounded-lg wave"></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
