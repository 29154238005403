<!-- DomainDropdown.vue -->
<template>
    <div class="tag-dropdown">
      <div class="dropdown-wrapper">
        <select v-model="selectedDomain" @change="onSelect" class="border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          <option value="">Select Domain</option>
          <option v-for="tag in filteredDomains" :key="tag.id" :value="tag.name">
            {{ tag.name }}
          </option>
        </select>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "DomainDropdown",
    props: {
      allTags: {
        type: Array,
        default: () => []
      },
      defaultTag: {
        type: String,
        default: ""
      },
      label: {
        type: String,
        default: "Domain"
      }
    },
    data() {
      return {
        selectedDomain: this.defaultTag
      };
    },
    computed: {
      filteredDomains() {
        return this.allTags.filter(tag => tag.category === "Domain");
      }
    },
    methods: {
      onSelect() {
        this.$emit("domainSelected", this.selectedDomain);
      },
      colorForTag(tagName) {
        // Simple logic for color. You might enhance this.
        const tag = this.allTags.find(t => t.name === tagName);
        return tag ? tag.color : "#000000";
      }
    }
  };
  </script>
  
  <style scoped>
  .tag-dropdown {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.tag-color-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.category-label {
  margin-left: 10px;
}

.dropdown-wrapper {
  display: flex;
  align-items: center;
}

select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
}

.tag-color {
  width: 20px;
  height: 20px;
}
  </style>