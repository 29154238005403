import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      allJobList: [],
      allTags: [], //[{category:<>, name: <>}]
      levelsHexColors: {
        Default: "rgba(0,0,0, 0.8)",
        "Key Skills": "rgba(231, 65, 7, 0.8)",
        Seniority: "rgba(0, 145, 80, 0.8)",
        "Work Type": "rgba(165, 42, 42,0.8)",
        Location: "rgba(134, 219, 166,0.8)",
        "Salary Range": "rgba(247, 124, 142,0.8)",
        Placement: "rgba(0, 102, 204,0.8)",
        Department: "rgba(251,234,31,0.8)",
      },
      companyLevelsHexColors: {
        Industry: "rgba(62,163,220, 0.8)",
        "Company Type": "rgba(157,21, 120, 0.8)",
        "Company Size": "rgba(58, 44, 129, 0.8)",
      },
      topLevelsHexColors: {
        Role: "rgba(157,21, 120, 0.8)",
      },
    };
  },
  mutations,
  actions,
  getters,
};
