export default {
  accessToken(state) {
    return state.accessToken;
  },
  refreshToken(state) {
    return state.refreshToken;
  },
  isAuthenticated(state) {
    return !!state.accessToken;
  },
  didAutoLogout(state) {
    return state.didAutoLogout;
  },
  authorizationHeader(state) {
    return {
      Authorization: "Bearer " + state.accessToken,
    };
  },
  refreshAuthorizationHeader(state) {
    return {
      Authorization: "Bearer " + state.refreshToken,
    };
  },
  backendUrl(state) {
    return state.backendUrl;
  },
  user(state) {
    return state.user;
  },
  actualUserDetails(state) {
    return state.actualUserDetails;
  },
  isAdmin(state) {
    return state.user.isAdmin;
  },
  isSuperAdmin(state) {
    return ["mathias@kooku.de", "admin@kooku.de"].includes(state.user.mail);
  },
  roleName(state) {
    return state.user.roleName;
  },
  isRoleClient(state) {
    return state.user.roleName === "ROLE_CLIENT";
  },
  isRoleCustomClient(state) {
    return state.user.roleName === "ROLE_CLIENT_NEW";
  },
  isRoleUser(state) {
    return state.user.roleName === "ROLE_USER";
  },
  isRoleInsideCompany(state) {
    return ["ROLE_USER", "ROLE_ADMIN"].includes(state.user.roleName);
  },
  username(state) {
    return state.user.name;
  },
  isPresentationMode(state) {
    return state.isPresentationMode;
  },
  isBlurEffectClass(state) {
    return state.isPresentationMode ? "blur-effect" : "";
  },
  userInitials(state) {
    if (state.user.name) {
      return state.user.name
        .split(" ")
        .map((word) => word[0])
        .join("");
    }
  },
  noShowAtsConnector(state) {
    return state.user.noShowAtsConnector;
  },
};
