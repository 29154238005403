<!-- RoleDropdown.vue -->
<template>
    <div class="tag-dropdown">
      <div class="dropdown-wrapper">
        <select v-model="selectedRole" @change="onSelect" class="border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          <option value="">Select Role</option>
          <option v-for="tag in filteredRoles" :key="tag.id" :value="tag.name">
            {{ tag.name }}
          </option>
        </select>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "RoleDropdown",
    props: {
      allTags: {
        type: Array,
        default: () => []
      },
      selectedDomain: {
        type: String,
        required: true
      },
      defaultTag: {
        type: String,
        default: ""
      },
      label: {
        type: String,
        default: "Role"
      }
    },
    data() {
      return {
        selectedRole: this.defaultTag
      };
    },
    computed: {
      filteredRoles() {
        // Only show roles that belong to the selected domain.
        return this.allTags.filter(
          tag =>
            tag.category === "Role" &&
            tag.main_cluster === this.selectedDomain
        );
      }
    },
    methods: {
      onSelect() {
        this.$emit("roleSelected", this.selectedRole);
      },
      colorForTag(tagName) {
        const tag = this.allTags.find(t => t.name === tagName);
        return tag ? tag.color : "#000000";
      }
    }
  };
  </script>

<style scoped>
  .tag-dropdown {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.tag-color-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.category-label {
  margin-left: 10px;
}

.dropdown-wrapper {
  display: flex;
  align-items: center;
}

select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
}

.tag-color {
  width: 20px;
  height: 20px;
}
  </style>