<template>
  <app-header />

  <div class="m-4 sm:m-10">
    <div class="grid grid-cols-12 gap-4 w-full">
      <div class="col-span-12 sm:col-span-4">
        <div class="flex flex-1 flex-row">
          <p class="text-lg sm:text-2xl font-bold">
            {{ $t("job-clustering.your-jobs") }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-1 flex-col sm:flex-row mt-5 mb-5 items-center">
      <p class="mr-3">Sort By:</p>
      <select
        id="sort by"
        name="sort by"
        class="block w-full sm:w-72 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        @change="onSortBy($event.target.value)"
      >
        <option value="Name">Name</option>
        <option value="Hired">Hired</option>
        <option value="Missing Assigned Roles">Missing Assigned Roles</option>
      </select>
    </div>

    <div>
    <div class="grid grid-cols-12 gap-4 items-center">
      <div class="col-span-12 md:col-span-8 flex flex-col sm:flex-row gap-4">
        <domain-dropdown
          :allTags="allTags"
          label="Domain"
          @domainSelected="onDomainSelected"
          class="w-1/4"
        />
        <role-dropdown
          :allTags="allTags"
          :selectedDomain="selectedDomain"
          label="Role"
          @roleSelected="onRoleSelected"
          class="w-1/4"
        />
      </div>

      <div class="col-span-12 md:col-span-4 flex justify-end gap-4">
        <div class="pr-10 text-end">
          <label class="switch">
            <input
              type="checkbox"
              v-model="showOnlyHiredJobs"
              @change="filterJobsByTags"
            >
            <span class="slider round"></span>
          </label>
          <div>
            <span class="toggle-label">Hired Jobs</span>
          </div>
        </div>
        <div class="pr-10 text-end">
          <label class="switch">
            <input
              type="checkbox"
              v-model="showExpertView"
              @change="fetchExpertViewDataFromBackend"
            >
            <span class="slider round"></span>
          </label>
          <div>
            <span class="toggle-label">Expert View</span>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden sm:block mt-4 text-white">
      <button
        @click="toggleAccordion"
        class="w-full text-left py-2 px-4 rounded-md bg-gray-400 hover:bg-gray-500 focus:outline-none"
      >
        <span>Additional Filters</span>
        <span class="float-right font-bold">{{ showAdditionalFilters ? '−' : '+' }}</span>
      </button>
    </div>

    <transition name="accordion">
      <div v-show="showAdditionalFilters" class="overflow-hidden bg-gray-100 ml-2 mr-2 rounded-b-md">
        <div class="grid grid-cols-12 gap-4 w-full hidden sm:grid ml-4 mr-4 mt-2">
          <div
            v-for="(color, level) in allHashtagLevels"
            :key="level"
            class="col-span-12 sm:col-span-6 md:col-span-3 lg:col-span-2"
          >
            <tag-dropdown
              :allTags="allTags"
              :category="getCategoryName(level)"
              :defaultTag="level"
              :allHashtagLevels="allHashtagLevels"
              :clearTrigger="clearTrigger"
              @tagSelected="onTagSelected(level, $event)"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>

    <div class="grid grid-cols-12 gap-4 mt-2">
      <div class="col-span-12 md:col-span-2 flex justify-around items-center mt-4 md:mt-0">
        <button
          class="bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded shadow-md hover:bg-[#3ea3dc]"
          @click="downloadPDF"
        >
          <span class="text-white">PDF</span>
        </button>
        <button
          class="bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded shadow-md hover:bg-[#3ea3dc]"
          @click="downloadExcel"
        >
          <span class="text-white">Excel</span>
        </button>
        <button
          class="bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded shadow-md hover:bg-[#3ea3dc]"
          @click="downloadCSV"
        >
          <span class="text-white">CSV</span>
        </button>
      </div>

      <div class="col-span-12 md:col-span-4 flex flex-wrap content-center">
        <div
          v-for="tag in searchTerms"
          :key="tag"
          class="badge rounded-md border-[#2197a7] bg-[#2197a7] hover:bg-[#186c77] ml-1 mt-1"
        >
          <p class="text-xs">{{ tag }}</p>
          <span class="cursor-pointer" @click="onTermRemove(tag)">
            <XIcon class="w-4 h-4 hover:text-gray-400 text-white" />
          </span>
        </div>
      </div>

      <div class="col-span-12 md:col-span-6">
        <div class="flex flex-col md:flex-row flex-1 justify-between items-center md:justify-end">
          <div class="flex mb-4 md:mb-0">
            <button
              class="bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md hover:bg-[#3ea3dc]"
              data-test="all-tags-button"
              @click="onOpenModal('allTagsModal')"
            >
              <span class="text-white">All Tags</span>
            </button>
            <button
              class="bg-gray-400 text-gray-800 font-bold py-2 px-4 mr-4 rounded shadow-md hover:bg-[#3ea3dc]"
              @click="clearAllTags"
            >
              <span class="text-white">Clear All Tags</span>
            </button>
          </div>
          <input
            type="text"
            v-model="searchText"
            data-test="searchInput"
            @keyup.enter="onAddSearchTerm"
            placeholder="Press enter to search the term"
            class="input input-bordered w-full max-w-xs"
          />
        </div>
      </div>
    </div>

    

   

    <div>
    <!-- Show Skeleton Loader When Loading -->
    <skeleton-job-list
      v-if="loading"
      class="flex flex-col sm:flex-row flex-start p-6 bg-gray-200 rounded-lg mt-3"
    />

    <!-- Show Expert View When Not Loading -->
    <div
      v-else-if="showExpertView"
      class="flex flex-col sm:flex-row flex-start p-4 bg-gray-200 rounded-lg mt-3 overflow-auto"
    >
      <div
        class="flex flex-col p-4 bg-gray-200 rounded-lg w-full flex-wrap"
        style="flex-flow: wrap;"
      >
        <!-- Header Grid -->
        <div
          class="grid gap-4 w-full text-xs sm:text-sm text-center shadow-xl header-item responsive-grid"
          style="grid-template-columns: repeat(16, minmax(0, 1fr));"
        >
          <div class="col-span-1 sm:col-span-1">Project <br />Code</div>
          <div class="col-span-2 sm:col-span-2">Tags</div>
          <div class="col-span-1 sm:col-span-1">{{ $t("job-kpi.candidate-sourced") }}</div>
          <div class="col-span-1 sm:col-span-1">{{ $t("job-kpi.candidate-contacted") }}</div>
          <div class="col-span-1 sm:col-span-1">{{ $t("job-kpi.candidate-feedback") }}</div>
          <div class="col-span-1 sm:col-span-1">{{ $t("job-kpi.candidate-interested") }}</div>
          <div class="col-span-1 sm:col-span-1">{{ $t("job-kpi.candidate-phoned") }}</div>
          <div class="col-span-1 sm:col-span-1">{{ $t("job-kpi.candidate-introduced") }}</div>
          <div class="col-span-1 sm:col-span-1">{{ $t("job-kpi.candidate-interviewed") }}</div>
          <div class="col-span-1 sm:col-span-1">{{ $t("job-kpi.candidate-offered") }}</div>
          <div class="col-span-1 sm:col-span-1">{{ $t("job-kpi.candidate-hired") }}</div>
          <div></div>
          <div class="col-span-1 sm:col-span-1">Project Time (Hours)</div>
          <div class="col-span-1 sm:col-span-1">Project Time (Week)</div>
          <div class="col-span-1 sm:col-span-1">Project Date <br />(Start & End)</div>
        </div>

        <!-- Job Cards -->
        <job-card
          v-for="job in filterJobs"
          :key="job"
          :expertView="true"
          :jobDetails="job"
          :searchTerms="searchTerms"
        />

        <!-- Footer Grid (Average) -->
        <div
          v-if="showOnlyHiredJobs"
          class="grid gap-4 w-full text-xs sm:text-sm text-center shadow-xl header-item mt-4"
          style="grid-template-columns: repeat(16, minmax(0px, 1fr));"
        >
          <div class="col-span-12"></div>
          <div class="col-span-2 sm:col-span-1">Average:</div>
          <div class="col-span-2 sm:col-span-1">{{ averageProjectTimeHours }}</div>
          <div class="col-span-2 sm:col-span-1">{{ averageProjectTimeWeeks }}</div>
        </div>
      </div>
    </div>


    <!-- Show Default View When Not Loading -->
    <div
      v-else
      class="flex flex-col sm:flex-row flex-start p-4 bg-gray-200 rounded-lg mt-3"
    >
      <div
        class="flex flex-col p-4 bg-gray-200 rounded-lg w-full flex-wrap"
        style="flex-flow: wrap;"
      >
        <div class="grid grid-cols-12 gap-4 w-full text-xs sm:text-sm text-center shadow-xl header-item">
          <div class="col-span-2 sm:col-span-1">Project <br />Code</div>
          <div class="col-span-4 sm:col-span-3">Job <br />Title</div>
          <div class="col-span-2 sm:col-span-1">Client <br />Name</div>
          <div class="col-span-4 sm:col-span-1">Recruiting Specialist</div>
          <div class="col-span-2 sm:col-span-1">Assigned Roles Status</div>
          <div class="col-span-2 sm:col-span-1">Job Hire <br />Count</div>
          <div class="col-span-2 sm:col-span-1"></div>
          <div class="col-span-2 sm:col-span-1">Project Time (Hours)</div>
          <div class="col-span-2 sm:col-span-1">Project Time (Week)</div>
          <div class="col-span-2 sm:col-span-1">Project Date <br />(Start & End)</div>
        </div>

        <job-card
          v-for="job in filterJobs"
          :key="job"
          :expertView="false"
          :jobDetails="job"
          :searchTerms="searchTerms"
        />

        <div
          v-if="showOnlyHiredJobs"
          class="grid grid-cols-12 gap-4 w-full text-xs sm:text-sm text-center font-bold mt-4 shadow-xl header-item"
        >
          <div class="col-span-8"></div>
          <div class="col-span-2 sm:col-span-1">Average:</div>
          <div class="col-span-2 sm:col-span-1">{{ averageProjectTimeHours }}</div>
          <div class="col-span-2 sm:col-span-1">{{ averageProjectTimeWeeks }}</div>
        </div>
      </div>
    </div>
  </div>

    <all-tags-modal
      v-if="openModal === 'allTagsModal'"
      @onModalClose="onOpenModal('')"
    />
  </div>
</template>

<script>
import AppHeader from "../../../components/layout/header/AppHeader.vue";
import JobCard from "./JobCard.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import SkeletonJobList from "./Skeleton/SkeletonJobList.vue";
import { XIcon } from "@heroicons/vue/solid";
import {
  getGeneralDataInCache,
  storeGeneralDataInCache,
} from "../../../components/helperFunctions/DataCaching";
import AllTagsModal from "../../../components/Modals/AllTags/AllTagsModal.vue";
import TagDropdown from "../../../components/ui/TagDropdown.vue";
import DomainDropdown  from "../../../components/ui/DomainDropdown.vue";
import RoleDropdown  from "../../../components/ui/RoleDropdown.vue";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

export default {
  components: {
    AppHeader,
    JobCard,
    SkeletonJobList,
    XIcon,
    AllTagsModal,
    TagDropdown,
    DomainDropdown,
    RoleDropdown,
  },
  data() {
    return {
      filterJobs: [],
      searchText: "",
      searchTag: "",
      searchTerms: [],
      openModal: "",
      jobs: [],
      loading: false,
      allTags: [],
      selectedTags: {},
      clearTrigger: false,
      showOnlyHiredJobs: false,
      showExpertView: false,
      columns: [],
      rows: [],
      selectedDomain: "", 
      selectedRole: "",
      showAdditionalFilters: false,
    };
  },
  mounted() {
    if (getGeneralDataInCache("allJobs")) {
      this.postProcessOfFetchedData(getGeneralDataInCache("allJobs"));
    } else {
      this.fetchDataFromBackend();
    }
    this.fetchAllTags();
  },
  methods: {
    ...mapActions(["setAllJobList", "setAllTags"]),

    fetchDataFromBackend() {
      this.loading = true;
      let headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + `/job/all-jobs`, {
          headers,
        })
        .then((response) => {
          let data = response.data.map((value) => Object.values(value)[0]);

          // Iterate through the 'data' array and process each 'value' object
          data = data.map((value) => {
            const { job_tags } = value;

            // if case: If 'job_tags' is null, set 'job_tags' and 'tag_category' to empty arrays
            if (job_tags === null) {
              return {
                ...value,
                job_tags: [],
                tag_category: [],
              };
            }
            // else case: if job_tags is not null then set the values
            // Split the 'job_tags' string into an array of key-value pairs
            const keyValuePairs = job_tags.slice(1, -1).split(",");

            return {
              ...value,
              job_tags: keyValuePairs.map((pair) => pair.split(":")[0].trim()), // Extract the keys and remove leading/trailing spaces
              tag_category: keyValuePairs.map((pair) =>
                pair.split(":")[1].trim()
              ),
            };
          });

          this.postProcessOfFetchedData(data);
        })
        .catch((error) => console.log(error));
    },
    fetchExpertViewDataFromBackend() {
      this.loading = true;
      let headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + `/job/all-jobs-expert-view`, {
          headers,
        })
        .then((response) => {
          let data = response.data.map((value) => Object.values(value)[0]);

          // Iterate through the 'data' array and process each 'value' object
          data = data.map((value) => {
            const { job_tags } = value;

            // if case: If 'job_tags' is null, set 'job_tags' and 'tag_category' to empty arrays
            if (job_tags === null) {
              return {
                ...value,
                job_tags: [],
                tag_category: [],
              };
            }
            // else case: if job_tags is not null then set the values
            // Split the 'job_tags' string into an array of key-value pairs
            const keyValuePairs = job_tags.slice(1, -1).split(",");

            return {
              ...value,
              job_tags: keyValuePairs.map((pair) => pair.split(":")[0].trim()), // Extract the keys and remove leading/trailing spaces
              tag_category: keyValuePairs.map((pair) =>
                pair.split(":")[1].trim()
              ),
            };
          });

          this.postProcessOfFetchedData(data);
        })
        .catch((error) => console.log(error));
    },
    postProcessOfFetchedData(data) {
      this.filterJobs = [...data];
      this.setAllJobList(this.filterJobs.slice()); // using slice to get a copy of filterjobs variable
      storeGeneralDataInCache("allJobs", this.allJobList);
      setTimeout(() => {
        // assign time out because it is not changing filterJobs variable if run directly
        this.onSortBy("Name");
      }, 500);
      this.loading = false;
    },
    onAddSearchTerm() {
      storeGeneralDataInCache("allJobs", this.allJobList);

      // Push only when searchText is not empty
      if (this.searchText !== "") {
        this.searchTerms.push(this.searchText.toLowerCase());
        this.searchText = "";
      }

      this.filterJobs = this.allJobList.filter((job) => {
        const hasTermInJobName = this.searchTerms.some((term) =>
          job.job_name.toLowerCase().includes(term.toLowerCase())
        );
        const hasTermInJobTags = this.searchTerms.some((term) =>
          job.job_tags.some((tag) => tag.toLowerCase() === term.toLowerCase())
        );
        const matchesSearchTerms = this.searchTerms.length === 0 || hasTermInJobName || hasTermInJobTags;

        // Include only hired jobs if toggle is on
        const matchesHiredStatus = !this.showOnlyHiredJobs || job.job_has_hire === "true";

        return matchesSearchTerms && matchesHiredStatus;
      });
    },
    onTermRemove(term) {
      let index = this.searchTerms.findIndex((value) => value === term);
      this.searchTerms.splice(index, 1);

      if (this.searchTerms.length > 0) {
        this.onAddSearchTerm();
      } else {
        this.filterJobs = [...this.allJobList];
        if (this.showOnlyHiredJobs) {
          this.filterJobs = this.filterJobs.filter(job => job.job_has_hire === "true");
        }
      }
    },
    onSortBy(value) {
      if (value === "Name") {
        this.filterJobs.sort((a, b) => {
          // Convert names to lowercase for case-insensitive sorting
          const nameA = a.job_name.toLowerCase();
          const nameB = b.job_name.toLowerCase();

          // Compare names and return comparison result
          if (nameA < nameB) {
            return -1; // Name A comes before Name B
          } else if (nameA > nameB) {
            return 1; // Name A comes after Name B
          } else {
            return 0; // Names are equal
          }
        });
      } else if(value === "Missing Assigned Roles"){
        let sortBy = "is_all_roles_assigned";

        this.filterJobs.sort((a, b) => {
          // Sort true values before false values
          if (a[sortBy] === b[sortBy]) {
            // If both have the same value, maintain the original order
            return 0;
          } else if (a[sortBy] === "true") {
            // 'true' values come before 'false' values
            return 1;
          } else {
            // 'false' values come after 'true' values
            return -1;
          }
        });
      }
      else{
        let sortBy = "job_has_hire";
        this.filterJobs.sort((a, b) => {
          // Sort true values before false values
          if (a[sortBy] === b[sortBy]) {
            // If both have the same value, maintain the original order
            return 0;
          } else if (a[sortBy] === "true") {
            // 'true' values come before 'false' values
            return -1;
          } else {
            // 'false' values come after 'true' values
            return 1;
          }
        });
      }
    },
    fetchAllTags() {
      let headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + `/tag/all-tags`, {
          headers,
        })
        .then((response) => {
          this.allTags = response.data;
          this.setAllTags(response.data);
          storeGeneralDataInCache("allTags", response.data);
        })
        .catch((error) => console.log(error));
    },
    getCategoryName(level) {
      const categoryMapping = {
        level1: 'category1',
        level2: 'category2',
        level3: 'category3'
      };
      return categoryMapping[level] || level; 
    },
    onTagSelected(level, selectedTag) {
      this.selectedTags[level] = selectedTag;

      // Trigger filtering based on selected tags
      this.filterJobsByTags();
    },
    filterJobsByTags() {
      
      const selectedTagsArray = Object.values(this.selectedTags).filter(tag => tag !== 'Select');

      this.filterJobs = this.allJobList.filter(job => {
      const matchesTags = selectedTagsArray.length === 0 || selectedTagsArray.every(tag => job.job_tags.includes(tag));
       // Check Domain filter inside job_tags
       const matchesDomain = !this.selectedDomain ||
        job.job_tags.includes(this.selectedDomain);

      // Check Role filter inside job_tags
      const matchesRole = !this.selectedRole ||
        job.job_tags.includes(this.selectedRole);
      const matchesHiredStatus = !this.showOnlyHiredJobs || job.job_has_hire === "true";
      const matchesSearchTerms = this.searchTerms.length === 0 || 
        this.searchTerms.some(term => 
          job.job_name.toLowerCase().includes(term.toLowerCase()) || 
          job.job_tags.some(tag => tag.toLowerCase() === term.toLowerCase())
        );

      return matchesTags && matchesDomain && matchesRole && matchesHiredStatus && matchesSearchTerms;
    });
    },
    onOpenModal(modalName) {
      this.openModal = modalName;
    },
    clearAllTags() {
      this.clearTrigger = !this.clearTrigger;
      this.selectedTags = {}; 
      this.selectedDomain = '';
      this.selectedRole = '';
      this.filterJobsByTags(); 
      this.onSortBy("Name");
    },
    toggleHiredJobs() {
      this.showOnlyHiredJobs = !this.showOnlyHiredJobs;
      this.onAddSearchTerm();
    },
    onDomainSelected(selectedDomain) {
      this.selectedDomain = selectedDomain;
      this.filterJobsByTags();
    },
    onRoleSelected(selectedRole) {
      this.selectedRole = selectedRole;
      this.filterJobsByTags();
    },
    toggleAccordion() {
      this.showAdditionalFilters = !this.showAdditionalFilters;
    },
    downloadPDF() {
      const doc = new jsPDF({
      orientation: this.showExpertView ? 'landscape' : 'portrait',
      });
      
      const logo = new Image();
      logo.src = require('@/assets/logo_transparent.png');
      doc.addImage(logo, 'PNG', 10, 10, 20, 10); 

      if(this.showExpertView){
      this.columns = [
        'Project Code', 'Job Title', 'Client Name', 'Recruiting Specialist', 'Assigned Roles Status', 'Job Hire Count', 'Project Time (Hours)', 'Project Time (Week)', 'Project Date (Start)', 'Project Date (End)',
        'KPI - Candidates Sourced', 'KPI - Candidates Contacted', 'KPI - Candidates Feedback', 'KPI - Candidates Interested', 'KPI - Candidates Phoned', 'KPI - Candidates Introduced', 'KPI - Candidates Interviewed', 'KPI - Candidates Offered', 'KPI - Candidates Hired'
      ];
      this.rows = this.filterJobs.map(job => [
      job.job_project_code, job.job_name, job.job_project_name, job.job_recruiting_specialist, job.is_all_roles_assigned, job.job_hire_count, job.job_time_hours, job.job_time_weeks, job.job_date_start, job.job_date_end,
      job.kpi_candidate_sourced, job.kpi_candidate_contacted, job.kpi_candidate_feedback, job.kpi_candidate_interested, job.kpi_candidate_phoned, job.kpi_candidate_introduced, job.kpi_candidate_interviewed, job.kpi_candidate_offered, job.kpi_candidate_hired
      ]);
      }
      else{
        this.columns = [
        'Project Code', 'Job Title', 'Client Name', 'Recruiting Specialist', 'Assigned Roles Status', 'Job Hire Count', 'Project Time (Hours)', 'Project Time (Week)', 'Project Date (Start)', 'Project Date (End)'
      ];
      this.rows = this.filterJobs.map(job => [
      job.job_project_code, job.job_name, job.job_project_name, job.job_recruiting_specialist, job.is_all_roles_assigned, job.job_hire_count, job.job_time_hours, job.job_time_weeks, job.job_date_start, job.job_date_end
      ]);
      }
      const averageRow = [
        'Average', '', '', '', '', '', this.averageProjectTimeHours, this.averageProjectTimeWeeks, ''
      ];
      if (this.showOnlyHiredJobs) {
        this.rows.push(averageRow);
      }

      // Add table
      doc.autoTable({
        head: [this.columns],
        body: this.rows,
        startY: 40,
        styles: {
          fontSize: 8, 
          cellPadding: 2,
          overflow: 'linebreak', // Ensures text doesn't break unexpectedly
        },
        columnStyles: {
          1: { cellWidth: 'auto' }, // Adjust column width dynamically
          2: { cellWidth: 40 },  // Set a fixed width to avoid breaking
        },
        headStyles: {
          fillColor: [62, 163, 220], 
          fontSize: 9,
          halign: 'center', 
        },
        bodyStyles: {
          fontSize: 8,
        },
      });

      // Add footer with current date and time
      const now = new Date();
      const dateStr = now.toLocaleDateString();
      const timeStr = now.toLocaleTimeString();
      doc.setFontSize(8);
      doc.text(`Generated on: ${dateStr} ${timeStr}`, 200, doc.internal.pageSize.height - 10, { align: 'right' });

      // Save the PDF
      doc.save('Kooku_jobs.pdf');
    },
    downloadExcel() {

      if(this.showExpertView){
      this.rows = this.filterJobs.map(job => ({
        'Project Code': job.job_project_code,
        'Job Title': job.job_name,
        'Client Name': job.job_project_name,
        'Recruiting Specialist': job.job_recruiting_specialist,
        'Assigned Roles Status': job.is_all_roles_assigned,
        'Job Hire Count': job.job_hire_count,
        'Project Time (Hours)': job.job_time_hours,
        'Project Time (Week)': job.job_time_weeks,
        'Project Date (Start)': job.job_date_start,
        'Project Date (End)': job.job_date_end,
        'KPI - Candidates Sourced': job.kpi_candidate_sourced, 
        'KPI - Candidates Contacted': job.kpi_candidate_contacted,
        'KPI - Candidates Feedback': job.kpi_candidate_feedback, 
        'KPI - Candidates Interested': job.kpi_candidate_interested, 
        'KPI - Candidates Phoned': job.kpi_candidate_phoned, 
        'KPI - Candidates Introduced': job.kpi_candidate_introduced, 
        'KPI - Candidates Interviewed': job.kpi_candidate_interviewed, 
        'KPI - Candidates Offered': job.kpi_candidate_offered, 
        'KPI - Candidates Hired': job.kpi_candidate_hired
      }));
    }
    else{
      this.rows = this.filterJobs.map(job => ({
        'Project Code': job.job_project_code,
        'Job Title': job.job_name,
        'Client Name': job.job_project_name,
        'Recruiting Specialist': job.job_recruiting_specialist,
        'Assigned Roles Status': job.is_all_roles_assigned,
        'Job Hire Count': job.job_hire_count,
        'Project Time (Hours)': job.job_time_hours,
        'Project Time (Week)': job.job_time_weeks,
        'Project Date (Start)': job.job_date_start,
        'Project Date (End)': job.job_date_end
      }));
    }

    
      const averageRow = {
        'Project Code': 'Average',
        'Job Title': '',
        'Client Name': '',
        'Recruiting Specialist': '',
        'Assigned Roles Status': '',
        'Job Hire Count': '',
        'Project Time (Hours)': this.averageProjectTimeHours,
        'Project Time (Week)': this.averageProjectTimeWeeks
      };
      if(this.showOnlyHiredJobs){
      this.rows.push({});
      this.rows.push(averageRow);
      }

      const ws = XLSX.utils.json_to_sheet(this.rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Jobs');
      XLSX.writeFile(wb, 'Kooku_jobs.xlsx');
    },
    downloadCSV() {
      if(this.showExpertView){
        this.rows = this.filterJobs.map(job => ({
        'Project Code': job.job_project_code,
        'Job Title': job.job_name,
        'Client Name': job.job_project_name,
        'Recruiting Specialist': job.job_recruiting_specialist,
        'Assigned Roles Status': job.is_all_roles_assigned,
        'Job Hire Count': job.job_hire_count,
        'Project Time (Hours)': job.job_time_hours,
        'Project Time (Week)': job.job_time_weeks,
        'Project Date (Start)': job.job_date_start,
        'Project Date (End)': job.job_date_end,
        'KPI - Candidates Sourced': job.kpi_candidate_sourced, 
        'KPI - Candidates Contacted': job.kpi_candidate_contacted,
        'KPI - Candidates Feedback': job.kpi_candidate_feedback, 
        'KPI - Candidates Interested': job.kpi_candidate_interested, 
        'KPI - Candidates Phoned': job.kpi_candidate_phoned, 
        'KPI - Candidates Introduced': job.kpi_candidate_introduced, 
        'KPI - Candidates Interviewed': job.kpi_candidate_interviewed, 
        'KPI - Candidates Offered': job.kpi_candidate_offered, 
        'KPI - Candidates Hired': job.kpi_candidate_hired
      }));
  }
  else{
        this.rows = this.filterJobs.map(job => ({
        'Project Code': job.job_project_code,
        'Job Title': job.job_name,
        'Client Name': job.job_project_name,
        'Recruiting Specialist': job.job_recruiting_specialist,
        'Assigned Roles Status': job.is_all_roles_assigned,
        'Job Hire Count': job.job_hire_count,
        'Project Time (Hours)': job.job_time_hours,
        'Project Time (Week)': job.job_time_weeks,
        'Project Date (Start)': job.job_date_start,
        'Project Date (End)': job.job_date_end
      }));
  }
    
    const averageRow = {
      'Project Code': 'Average',
      'Job Title': '',
      'Client Name': '',
      'Recruiting Specialist': '',
      'Assigned Roles Status': '',
      'Job Hire Count': '',
      'Project Time (Hours)': this.averageProjectTimeHours,
      'Project Time (Week)': this.averageProjectTimeWeeks
    };
    
    if (this.showOnlyHiredJobs) {
      this.rows.push({});
      this.rows.push(averageRow);
    }

    // Convert array of objects to CSV format
    const csvContent = [
      ['Project Code', 'Job Title', 'Client Name', 'Recruiting Specialist', 'Assigned Roles Status', 'Job Hire Count', 'Project Time (Hours)', 'Project Time (Week)', 'Project Date (Start)', 'Project Date (End)',
        'KPI - Candidates Sourced', 'KPI - Candidates Contacted', 'KPI - Candidates Feedback', 'KPI - Candidates Interested', 'KPI - Candidates Phoned', 'KPI - Candidates Introduced', 'KPI - Candidates Interviewed', 'KPI - Candidates Offered', 'KPI - Candidates Hired'
      ],
      ...this.rows.map(row => Object.values(row))
    ].map(e => e.join(",")).join("\n");

    // Create a download link for the CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "Kooku_jobs.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  },
  computed: {
    ...mapGetters([
      "authorizationHeader",
      "backendUrl",
      "allJobList",
      "allHashtagLevels",
    ]),
    averageProjectTimeHours() {
      if (!this.showOnlyHiredJobs || this.filterJobs.length === 0) return "0.00";
      const validJobs = this.filterJobs.filter(job => !isNaN(job.job_time_hours));
      if (validJobs.length === 0) return "0.00";
      const totalHours = validJobs.reduce((acc, job) => acc + parseFloat(job.job_time_hours), 0);
      return (totalHours / validJobs.length).toFixed(2);
    },
    averageProjectTimeWeeks() {
      if (!this.showOnlyHiredJobs || this.filterJobs.length === 0) return "0";
      const validJobs = this.filterJobs.filter(job => !isNaN(job.job_time_weeks));
      if (validJobs.length === 0) return "0";
      const totalWeeks = validJobs.reduce((acc, job) => acc + parseFloat(job.job_time_weeks), 0);
      const averageWeeks = totalWeeks / validJobs.length;
      return Math.round(averageWeeks).toString();
    },
  },
  watch: {
    allJobList() {
      this.onAddSearchTerm();

      if (this.allJobList.length === 0) {
        this.fetchDataFromBackend();
      }
    },
  },
};
</script>

<style scoped>

.toggle-switch-wrapper {
  display: flex;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  flex-direction: column;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2197a7;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-label {
  font-size: 12px;
  color: #4A5568;
}

.header-item {
  background-color: #4a5568; 
  color: white;
  font-weight: bold; 
  padding: 0.5rem; 
  border-radius: 0.25rem;
}
.subheader-item {
  background-color: #8F97A5;
    color: white;
    font-weight: normal;
    font-size: small;
    padding: 0.5rem;
    border-radius: 0.25rem;
}
@media (max-width: 640px) {
  .toggle-switch-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 640px) {
  .responsive-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
  }
}
.accordion-enter-active,
.accordion-leave-active {
  transition: all 0.3s ease;
}
.accordion-enter,
.accordion-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>