<template>
  <div 
    v-if="expertView" 
    class="h-auto w-full bg-white hover:bg-gray-300 p-3 ml-1 mr-1 shadow-xl border-b-2 border-gray-400 responsive-expert-view"
  >
    <div class="flex flex-wrap justify-between items-center">
      <div class="grid gap-1 w-full items-center justify-items-center md:justify-items-start expert-view-body responsive-grid">
        <!-- Project Code -->
        <div class="col-span-1 relative">
          <div 
            class="badge rounded-md border-gray-400 bg-gray-400 hover:bg-black hover:cursor-pointer ml-1 box-content w-full"
            @mouseenter="showDetails = true"
            @mouseleave="showDetails = false"
          >
            <p class="text-xs">{{ jobDetails.job_project_code }}</p>
          </div>
          <!-- Tooltip for Job Name and Job Code -->
          <div 
            v-if="showDetails" 
            class="absolute bg-white border border-gray-300 shadow-lg rounded p-2 text-left w-max"
            style="top: 100%; left: 0; z-index: 10;"
          >
            <p class="text-sm font-bold">{{ jobDetails.job_name }}</p>
            <p class="text-xs"> {{ "("+ jobDetails.job_project_name +")"}} </p>
          </div>
        </div>
        
        <!-- Job Tags -->
        <div class="col-span-2 md:col-span-2 text-center justify-self-center">
          <hashtag-add-or-edit
            :hashtags="jobDetails.job_tags"
            :categories="jobDetails.tag_category"
            @onHashtagClick="onHashtagClick"
          />
        </div>

        <!-- KPI Metrics (Repeated Structure for all KPIs) -->
        <div 
          v-for="(kpi, index) in [
            jobDetails.kpi_candidate_sourced, 
            jobDetails.kpi_candidate_contacted, 
            jobDetails.kpi_candidate_feedback,
            jobDetails.kpi_candidate_interested, 
            jobDetails.kpi_candidate_phoned, 
            jobDetails.kpi_candidate_introduced, 
            jobDetails.kpi_candidate_interviewed, 
            jobDetails.kpi_candidate_offered, 
            jobDetails.kpi_candidate_hired
          ]" 
          :key="index"
          class="col-span-1 text-center relative justify-self-center border-r-2 border-gray-400 p-2"
        >
          <span class="text-md flex items-center justify-center">
            <b>{{ kpi }}</b>
          </span>
        </div>

        <div class="col-span-1 text-center relative justify-self-center">
        </div>
        <!-- Time Hours & Time Weeks -->
        <div class="col-span-1 text-center relative justify-self-center">
          <span class="bg-gray-500 text-white rounded-full text-xs w-11 h-11 flex items-center justify-center">
            <b>{{ jobDetails.job_time_hours }}</b>
          </span>
        </div>
        <div class="col-span-1 text-center relative justify-self-center">
          <span class="bg-gray-500 text-white rounded-full text-xs w-11 h-11 flex items-center justify-center">
            <b>{{ jobDetails.job_time_weeks }}</b>
          </span>
        </div>

        <!-- Start & End Dates -->
        <div class="col-span-2 md:col-span-1 flex justify-end flex-col justify-self-center">
          <div class="badge rounded-md border-gray-400 bg-gray-400 hover:bg-black ml-1 w-auto">
            <p class="text-xs">{{ jobDetails.job_date_start }}</p>
          </div>
          <div class="badge rounded-md border-gray-400 bg-gray-400 hover:bg-black ml-1 mt-1 w-auto">
            <p class="text-xs">{{ jobDetails.job_date_end }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div v-else class="h-auto w-full bg-white hover:bg-gray-300 p-3 m-2 shadow-xl rounded-lg border border-gray-400">
    <div class="flex flex-wrap justify-between items-center">
      <div class="grid grid-cols-2 md:grid-cols-12 gap-4 w-full items-center justify-items-center md:justify-items-start">


        <!-- Project Code -->
        <div class="col-span-1">
          <div class="badge rounded-md border-gray-400 bg-gray-400 hover:bg-black ml-1 box-content w-full">
            <p class="text-xs">{{ jobDetails.job_project_code }}</p>
          </div>
        </div>

        <!-- Job Name -->
        <div class="col-span-2 md:col-span-3">
          <div
            class="flex flex-1 flex-wrap items-center cursor-pointer hover:text-teal-600"
            @click="openJobDetails"
          >
            <CheckCircleIcon v-if="jobDetails.job_has_hire === 'true'" class="w-6 h-6 mr-2" style="color: green" />
            <p
              class="text-md font-semibold mr-1"
              v-for="(word, i) in jobDetails.job_name.split(' ')"
              :key="i"
            >
              {{ word }}
            </p>
          </div>
        </div>

        <!-- Project Name -->
        <div class="col-span-1 text-center">
          <p class="text-xs font-semibold">{{ jobDetails.job_project_name }}</p>
        </div>

        <!-- Recruiting Specialist -->
        <div class="col-span-1 text-center justify-self-center">
          <p v-if="jobDetails.job_recruiting_specialist" class="text-md font-semibold text-teal-600">
            {{ jobDetails.job_recruiting_specialist }}
          </p>
        </div>

        <!-- Exclamation Icon -->
        <div class="col-span-1 text-center justify-self-center">
          <ExclamationCircleIcon v-if="jobDetails.is_all_roles_assigned === 'false'" class="w-6 h-6 text-red-500" />
        </div>

        <!-- Hire Count -->
        <div class="col-span-1 text-center relative justify-self-center">
          <UserGroupIcon v-if="jobDetails.job_hire_count > 0" class="w-6 h-6 text-gray-400" />
          <span v-if="jobDetails.job_hire_count > 0" class="absolute bg-green-700 text-white rounded-full text-xs w-4 h-4 flex items-center justify-center top-0 left-3/4">
            <b>{{ jobDetails.job_hire_count }}</b>
          </span>
        </div>

        <!-- Job Tags -->
        <div class="col-span-2 md:col-span-1 text-center justify-self-center">
          <hashtag-add-or-edit
            :hashtags="jobDetails.job_tags"
            :categories="jobDetails.tag_category"
            @onHashtagClick="onHashtagClick"
          />
        </div>

         <!-- Time Hours & Time Weeks -->
         <div class="col-span-1 text-center relative justify-self-center">
          <span class="bg-gray-500 text-white rounded-full text-xs w-11 h-11 flex items-center justify-center">
            <b>{{ jobDetails.job_time_hours }}</b>
          </span>
        </div>
        <div class="col-span-1 text-center relative justify-self-center">
          <span class="bg-gray-500 text-white rounded-full text-xs w-11 h-11 flex items-center justify-center">
            <b>{{ jobDetails.job_time_weeks }}</b>
          </span>
        </div>

        <!-- <div class="col-span-2">
          <div class="flex flex-1 justify-end">
            <button
              class="text-gray-800 font-bold p-2 rounded-xl inline-flex items-center"
              @click="onOpenUseAsTemplate()"
            >
              <span class="text-[#2197a7] hover:text-[#2a656d]">{{
                $t("job-clustering.template")
              }}</span>
            </button>
          </div>
        </div> -->

        <!-- Start & End Dates -->
        <div class="col-span-2 md:col-span-1 flex justify-end flex-col justify-self-center">
          <div class="badge rounded-md border-gray-400 bg-gray-400 hover:bg-black ml-1 w-auto">
            <p class="text-xs">{{ jobDetails.job_date_start }}</p>
          </div>
          <div class="badge rounded-md border-gray-400 bg-gray-400 hover:bg-black ml-1 mt-1 w-auto">
            <p class="text-xs">{{ jobDetails.job_date_end }}</p>
          </div>
        </div>


      </div>
    </div>
    <add-or-remove-hash-tags-modal
      :details="jobDetails"
      isForJob="true"
      v-if="onModal == 'AddOrRemoveHashtags'"
      @onHashtagSave="onHashtagSave"
    />

    <use-as-template-modal
      :job="jobDetails"
      isForJob="true"
      v-if="onModal == 'useAsTemplateModal'"
      @onModalClose="onModalClose"
    />
  </div>
</template>

<script>
import AddOrRemoveHashTagsModal from "../../../components/Modals/AddOrRemoveHashtags/AddOrRemoveHashTagsModal.vue";
import UseAsTemplateModal from "../../../components/Modals/JobClustering/UseAsTemplateModal.vue";
import { mapGetters } from "vuex";
import HashtagAddOrEdit from "../../../components/ui/HashtagAddOrEdit.vue";
import { CheckCircleIcon, ExclamationCircleIcon, UserGroupIcon } from "@heroicons/vue/solid";

export default {
  props: ["jobDetails", "searchTerms", "expertView"],
  components: {
    AddOrRemoveHashTagsModal,
    UseAsTemplateModal,
    HashtagAddOrEdit,
    CheckCircleIcon,
    ExclamationCircleIcon,
    UserGroupIcon,
  },
  computed: {
    ...mapGetters(["levelsHexColors"]),
  },
  methods: {
    onHashtagClick() {
      this.onModal = "AddOrRemoveHashtags";
    },
    openJobDetails() {
      this.$store.dispatch("setCompany", {
        newCompanyName: this.jobDetails.company_name,
        newCompanyId: this.jobDetails.company_id,
      });

      this.$store.dispatch("setJob", {
        newJobId: this.jobDetails.job_id,
        newJobName: this.jobDetails.job_name,
      });

      this.$router.push(
        `/dashboardJob/${this.jobDetails.company_id}/${this.jobDetails.job_id}`
      );
    },
    onModalClose() {
      // this.$router.go();
      this.onModal = "";
    },
    onOpenUseAsTemplate() {
      this.onModal = "useAsTemplateModal";
    },
    onHashtagSave() {
      this.onModal = "";
    },
    highlightSearchText(word) {
      // Remove special characters '()', '/', and '-' that have non-space characters before or after them,
      // then convert the resulting word to lowercase and trim any leading/trailing whitespace.
      const formattedWord = word
        .replaceAll(/(?<=[^\s])[()/-]|[()/-](?=[^\s])/g, "")
        .toLowerCase()
        .trim();

      // Check if any element in the searchTerms array includes the formatted search word
      const containsSearchTerm = this.searchTerms.some((item) =>
        item.includes(formattedWord)
      );
      // Return the appropriate CSS class for highlighting based on the result
      return containsSearchTerm ? "bg-amber-300" : null;
    },
  },
  data() {
    return {
      onModal: "",
      showDetails: false,
    };
  },
};
</script>

<style scoped>
.expert-view-body {
    grid-template-columns: repeat(16, minmax(0, 1fr));
}
.responsive-grid {
  grid-template-columns: repeat(16, minmax(0, 1fr));
}

/* Responsive Styles for Small Screens */
@media (max-width: 640px) {
  .responsive-grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;
  }
  .responsive-expert-view {
    padding: 1rem;
    margin: 0.5rem 0;
  }
  .responsive-grid .col-span-1,
  .responsive-grid .col-span-2 {
    text-align: center;
  }
}
</style>
